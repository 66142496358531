import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/layout';

const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    <div>
      <h1>Page not found</h1>
      <p>
        We couldn&apos;t find the page you were looking for. Try a topic on the left hand
        navigation, or reach out at&nbsp;
        <a href="https://www.gorillastack.com/support/">our support page</a>
        &nbsp;if you expected something at this location.
      </p>
    </div>
  </Layout>
);
NotFoundPage.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default NotFoundPage;
